
//Mudei os tamanhos de rem para em
$datepicker__border-radius: 0.9rem !default;
$datepicker__day-margin: 0.266rem !default;
$datepicker__font-size: 1.4rem !default;
$datepicker__font-family: "Helvetica Neue", Helvetica, Arial,
  sans-serif !default;
$datepicker__item-size: 2.3rem !default;
$datepicker__margin: 1rem !default;
$datepicker__navigation-size: 1.05rem !default;
$datepicker__triangle-size: 8px !default;
